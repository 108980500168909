"use client";

import { TopBar } from "@shared-components/global/top-bar";
import { Button } from "@shared-components/shadcn/button";
import { Checkbox } from "@shared-components/shadcn/checkbox";
import { api } from "@shared-server/api";
import { useLocalStorage } from "@shared-utils/hooks/use-local-storage";
import { useState } from "react";

type TermsAcceptancePromptProps = {
  children: React.ReactNode;
};

type TermItems = {
  key: string;
  header: string;
  content: React.ReactElement;
};

const termItems: TermItems[] = [
  {
    key: "dataNonGxp",
    header: "Data is not part of a GxP-process",
    content: (
      <>
        {
          "Please confirm that the way you use LEO AI does not directly support a LEO GxP process.  "
        }
      </>
    ),
  },
  {
    key: "dataNonPrivate",
    header: "Data is non-private",
    content: (
      <>
        {
          "Please verify that the data you use on LEO AI, as a prompt or upload, does not include any information that identifies or makes an individual identifiable. "
        }
      </>
    ),
  },
  {
    key: "trainingRead",
    header: "I have read the Training Material",
    content: (
      <>
        <a
          className="text-link"
          href="https://leopharma.sharepoint.com/teams/LEO-PHARMA-ACADEMY/SitePages/LEO-OpenAI.aspx"
          target="_blank"
          rel="noreferrer"
        >
          {"Open Training Material"}
        </a>
      </>
    ),
  },
  {
    key: "faqRead",
    header: "I have read the FAQ",
    content: (
      <>
        <a
          className="text-link"
          href="https://leopharma.sharepoint.com/teams/LEO-PHARMA-ACADEMY/Shared%20Documents/Digital%20Skills/LEO%20AI%20FAQ%2018Apr24.pdf?CT=1713436482631&OR=ItemsView"
          target="_blank"
          rel="noreferrer"
        >
          {"Open FAQ"}
        </a>
      </>
    ),
  },
  {
    key: "securityTraining",
    header: "Mandatory Security Training",
    content: (
      <>
        {
          "Remember to complete all mandatory Information Security training. To check whether any training is outstanding, check your library here. "
        }
        <a
          className="text-link"
          href="https://leo.metacompliance.com/Main/IndexAll"
          target="_blank"
          rel="noreferrer"
        >
          {"here"}
        </a>
        {"."}
      </>
    ),
  },
];

export const TermsAcceptancePrompt = (props: TermsAcceptancePromptProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  // if term is acceptted locally we optimistically show the main ui while checking if the terms are accepted
  const [termsAcceptedLocally, setTermsAcceptedLocally] = useLocalStorage(
    "terms-accepted",
    false,
  );

  // query and mutation
  const termsAcceptanceQuery = api.termsAcceptance.get.useQuery(undefined, {
    staleTime: Infinity,
  });
  const createTermsAcceptanceMutation =
    api.termsAcceptance.create.useMutation();

  // utils
  const acceptAndCountinue = () => {
    createTermsAcceptanceMutation.mutate();
    setTermsAcceptedLocally(true);
  };

  // evaluate if terms are accepted
  const termsAccepted =
    Boolean(termsAcceptanceQuery.data) ||
    createTermsAcceptanceMutation.isSuccess;

  if (termsAcceptanceQuery.isLoading) {
    return null;
  }

  // view logic and rendering
  if (
    termsAccepted ||
    (termsAcceptanceQuery.isLoading && termsAcceptedLocally) // showing main ui if terms are accepted locally
  ) {
    return <>{props.children}</>;
  }

  return (
    <>
      <TopBar aiTools={[]} />
      <div className="flex h-full justify-center p-4 md:items-center">
        <div className="max-w-[420px]">
          <h1 className="mb-4 font-bold">{"LEO AI"}</h1>
          <p className="text-lg">
            {"Please accept the following terms to start using LEO AI."}
          </p>
          <div className="pt-4">
            {termItems.map((termItem) => (
              <div
                key={termItem.key}
                className="ju mb-4 flex items-start gap-2"
              >
                <Checkbox
                  className="mt-0.5"
                  checked={checkedItems.includes(termItem.key)}
                  onCheckedChange={() =>
                    !checkedItems.includes(termItem.key)
                      ? setCheckedItems([...checkedItems, termItem.key])
                      : setCheckedItems(
                          checkedItems.filter(
                            (itemKey) => itemKey !== termItem.key,
                          ),
                        )
                  }
                />
                <div>
                  <h2>{termItem.header}</h2>
                  <p className="whitespace m-0">{termItem.content}</p>
                </div>
              </div>
            ))}
          </div>
          <Button
            onClick={() => acceptAndCountinue()}
            disabled={checkedItems.length !== termItems.length}
            className="w-full"
          >
            {"Accept & Continue"}
          </Button>
        </div>
      </div>
    </>
  );
};
